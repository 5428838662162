
import term from '@motionelements/helper/term.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import cookies from 'js-cookie';
import link, { setLocale as setLinkLocale } from '@motionelements/core/src/helpers/link.js';
import date, { setLocale as setDateLocale } from '@motionelements/core/src/helpers/date';
import wbr from '@motionelements/helper/wbr.js';
import siteUrl from '@motionelements/helper/siteUrl.js';
import * as teleport from '@motionelements/teleport';
import uuid from 'uuid/v4'; // upload file id generate
import schema from '@motionelements/helper/schema.js';
// import videoPlayer from '@/helpers/player/video.js';

export default (context, inject) => {
  setLinkLocale(context.app.store.$i18n.locale);
  setDateLocale(context.app.store.$i18n.locale);

  const nuxtLink = (path, targetLanguage = context.app.store.$i18n.locale) => link(path, targetLanguage);

  inject('term', term);
  inject('link', nuxtLink);
  inject('date', date);
  inject('schema', schema);
  inject('cookies', cookies);
  inject('siteUrl', siteUrl);
  inject('wbr', wbr);
  inject('uuid', uuid);
  inject('teleport', teleport);
  inject('scrollToTop', () => {
    window.scrollTo({ top: 0 });
  });

  // inject('siteUrl', siteUrl)
  // inject('videoPlayer', videoPlayer);
  // For Nuxt <= 2.12, also add 👇
  context.$term = term;
  context.$link = nuxtLink;
  context.$date = date;
  context.$schema = schema;
  context.$cookies = cookies;
  context.$siteUrl = siteUrl;
  context.$wbr = wbr;
  context.$uuid = uuid;
  context.$teleport = teleport;
  // context.$videoPlayer = videoPlayer;
};
