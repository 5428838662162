import cookies from 'js-cookie';
import { isMobile } from '@motionelements/core/src/helpers/browser.js';
import { initAccount } from './account-ai.service';
// Nuxt compatibility workaround
let store;

// Nuxt compatibility workaround
export function setStore(s) {
  store = s;
}

export function detectBrowser() {
  if (process.env.VUE_APP_NUXT && !process.client) {
    return true;
  }

  store.commit('browser/setMobile', isMobile());

  return true;
}

// init by API or localStorage
// can move to Vuex
export async function bootstrapApp(vm) {
  detectBrowser();

  // set site cookie to vuex
  const siteCookies = cookies.get();

  if (siteCookies) {
    store.dispatch('cookie/setCookies', siteCookies);
  }

  await initAccount(vm);
}
