//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'root-page',
  components: {
    BaseLayout: () => import('@/components/core/BaseLayout.vue'),
    TheNavBar: () => import('@/components/core/nav/TheNavBar.vue'),
    TheNavBarMobile: () => import('@/components/core/nav/TheNavBarMobile.vue'),
  },
};
