import cookies from 'js-cookie';
import * as accountApi from '@motionelements/core/src/api/account.js';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service';
// import store from '@/store/index.js';
import notificationService from '@motionelements/core/src/services/notification.service';
import { refreshJwtLanguage } from '@motionelements/core/src/services/language.service.js';
import link from '@motionelements/core/src/helpers/link';

import {
  COOKIE_JWT_KEY,
  COOKIE_JWT_SIGNATURE_KEY,
  COOKIE_CURRENCY_KEY,
  COOKIE_LANGUAGE_KEY,
  // vuex-persistence keys
  STORAGE_VUEX_ACCOUNT_KEY,
  STORAGE_VUEX_ACCOUNT_VERSION,
  STORAGE_VUEX_CART_KEY,
  STORAGE_VUEX_FAVORITES_KEY,
} from '@motionelements/core/src/app.constants.js';

// cache jwt payload
const jwtPayload = getJwtPayload();

let store;
// Nuxt compatibility workaround
export function setStore(s) {
  store = s;
}

function getAccountSession() {
  if (!process.server) {
    let accountSession = JSON.parse(sessionStorage.getItem(STORAGE_VUEX_ACCOUNT_KEY));
    if (accountSession) {
      let cookieCurrencyCode = _.get(jwtPayload, 'currency', null) || cookies.get(COOKIE_CURRENCY_KEY);
      cookieCurrencyCode = cookieCurrencyCode ? cookieCurrencyCode.toUpperCase() : null;
      const hash = `${store.state.user.jwt.id}|${store.state.user.language}|${cookieCurrencyCode}|${STORAGE_VUEX_ACCOUNT_VERSION}`;
      if (hash !== accountSession.hash) {
        sessionStorage.removeItem(STORAGE_VUEX_ACCOUNT_KEY);
        accountSession = null;
      }
      return accountSession;
    }
  }
}

export function isLoggedIn() {
  return _.has(jwtPayload, 'sub');
}

export function getCookieLanguageCode() {
  if (isLoggedIn()) {
    return _.get(jwtPayload, 'language', null);
  }
  return cookies.get(COOKIE_LANGUAGE_KEY);
}


// called from vuex
// handle non vuex related functions
export function changeCurrency(currencyCode) {
  currencyCode = currencyCode.toUpperCase();

  cookies.set(COOKIE_CURRENCY_KEY, currencyCode, { domain: '.motionelements.com', secure: true });

  if (process.env.NODE_ENV === 'development') {
    cookies.set(COOKIE_CURRENCY_KEY, currencyCode); // localhost fallback
  }

  if (isLoggedIn()) {
    // update lazily
    accountApi.updateAccount({
      currency: currencyCode,
    })
      .then(() => {
        window.location.reload(true);
      });
  } else {
    window.location.reload(true);
  }
}

// eslint-disable-next-line no-unused-vars
export function updateGoogleTagManager(data) {
  if (!process.server && typeof window.dataLayer !== 'undefined') {
    let memberType = 'member';
    if (data.admin) {
      memberType = 'admin';
    } else if (data.reseller) {
      memberType = 'reseller';
    } else if (data.artist) {
      memberType = 'artist';
    }

    const gtmData = {
      userId: data.id,
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      fullName: data.fullName,
      email: data.email,
      memberType: memberType,
      internal: data.internal,
      language: data.language,
    };

    window.dataLayer.push(gtmData);
  }
}

// eslint-disable-next-line no-unused-vars
function mapJwtData(jwtPayload) {
  return {
    id: jwtPayload.sub,
    fullName: _.get(jwtPayload, 'name'),
    firstName: _.get(jwtPayload, 'given_name'),
    lastName: _.get(jwtPayload, 'family_name'),
    username: _.get(jwtPayload, 'preferred_username'),
    language: _.get(jwtPayload, 'language', 'en'),
    currency: _.get(jwtPayload, 'currency', 'USD'),
    email: _.get(jwtPayload, 'email'),
    avatar: {
      url: _.get(jwtPayload, 'picture'),
    },
    subscriptions: _.get(jwtPayload, 'subscriptions'),
    artist: _.get(jwtPayload, 'artist'),
    internal: _.get(jwtPayload, 'internal', false),
  };
}

export function refreshAccount() {
  store.commit('user/setLoading', true);
  return accountApi.getAccount().then((response) => {
    store.commit('user/setLoading', false);
    const data = response.data.data;

    if (data) {
      // check if route language is same as account language?
      if (getCookieLanguageCode() !== data.language) {
        console.log('LANG DIFF', getCookieLanguageCode(), data.language);
        refreshJwtLanguage(data.language);
      }

      store.dispatch('user/setMember', data);
    }

    return response;
  }).catch((error) => {
    console.error(error);
    store.commit('user/setLoading', false);
    store.commit('user/setError', true);
    if (error.response.status === 401) {
      // unset JWT
      // need to specify full path and domain
      cookies.remove(COOKIE_JWT_KEY, { path: '/', domain: '.motionelements.com' });
      cookies.remove(COOKIE_JWT_SIGNATURE_KEY, { path: '/', domain: '.motionelements.com' });

      if (process.env.VUE_APP_ENV !== 'production') {
        // remove all jwt cookies from other domains
        cookies.remove(COOKIE_JWT_KEY);
        cookies.remove(COOKIE_JWT_SIGNATURE_KEY);
      }
      store.dispatch('user/clearMember');
      notificationService.handleResponseNotification({
        data: {
          messages: [
            {
              type: 'alert',
              level: 'info',
              title: 'Login again',
              details: 'Please login again.',
            },
          ],
        },
      });

      const doRedirectToLoginPage = true;

      if (doRedirectToLoginPage) {
        // route to login only if it's not login
        const currentPath = store.getters['route/fullPath'];

        if (currentPath.indexOf('/account/login') === -1) {
          window.location.href = link('/account/login').param('intended_url', currentPath).url();
        } else {
          console.log('skip redirect to same login page');
        }
      }
    }
  });
}

// initAccount via API
export function initAccount(vm) {
  if (isLoggedIn() && jwtPayload) {
    // map data from standard JWT fields
    const data = mapJwtData(jwtPayload);
    console.log(data);

    // jwt id
    const jwtId = _.get(jwtPayload, 'jti');
    // use as session id
    store.commit('user/setJwtId', jwtId);
    // set jwt member for temp
    store.commit('user/setJwtMember', data);

    updateGoogleTagManager(data);
  }
  const isStudioAiSite = process.env.VUE_APP_PROJECT_NAME === 'frontend-studio-ai';
  if (isStudioAiSite) {
    if (isLoggedIn() && jwtPayload) {
      store.dispatch('user/setCoins');
    } else {
      store.commit('user/setCoins', 20);
    }
  }

  if (!isLoggedIn()) {
    // clear account session storage
    // set final currency from cookie
    if (!process.server) {
      sessionStorage.removeItem(STORAGE_VUEX_ACCOUNT_KEY);
    }
    const cookieCurrencyCode = cookies.get(COOKIE_CURRENCY_KEY);
    if (cookieCurrencyCode) {
      store.dispatch('user/setCurrencyCode', cookieCurrencyCode);
    }
    sessionStorage.removeItem(STORAGE_VUEX_CART_KEY);
    sessionStorage.removeItem(STORAGE_VUEX_FAVORITES_KEY);
    return null;
  }

  const accountSession = getAccountSession();
  if (!_.get(accountSession, 'user.isLoaded')) {
    return refreshAccount();
  }

  store.dispatch('user/setMember', accountSession.user.member);
  // SET ROLLBAR MEMBER

  const rollbarPerson = {
    id: accountSession.user.member.id, // required
    username: accountSession.user.member.username,
    email: accountSession.user.member.email,
  };
  vm.$rollbar.configure({
    payload: {
      person: rollbarPerson,
    },
  });
}
