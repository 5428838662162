import api, { setStore as setApiStore } from '@motionelements/core/src/api/base-api-v2';
import { setStore as setAccountServiceStore } from '@/services/account-ai.service.js';
import { setStore as setAppServiceStore } from '@/services/app-ai.service.js';
import { setStore as setLanguageServiceStore } from '@motionelements/core/src/services/language.service.js';
import { setStore as setNotificationServiceStore } from '@motionelements/core/src/services/notification.service.js';
import meHelper from '@motionelements/helper';

export default function ({ app, $gtm }) {
  setAccountServiceStore(app.store);
  setAppServiceStore(app.store);
  setLanguageServiceStore(app.store);
  setNotificationServiceStore(app.store);
  setApiStore(app.store);
  $gtm.init(process.env.VUE_APP_GTM_ID);
  api.setLanguage(app.store.$i18n.locale);
  app.store.commit('user/setLanguage', app.store.$i18n.locale);
  meHelper.defaultLang.set(app.store.$i18n.locale);
}
