import createMutationsSharer from 'vuex-shared-mutations';

import vuexSharedMutations from '@motionelements/core/src/helpers/vuexSharedMutations.js';

// core localStorage

// core sessionStorage
import persistExperiment from '@motionelements/core/src/store/persists/sessionStorage/experiment.vuex.persistence.js';
import persistCurrency from '@motionelements/core/src/store/persists/sessionStorage/currency.vuex.persistence.js';

const isProduction = process.env.VUE_APP_ENV === 'production';

export default ({ store }) => {
  persistExperiment.plugin(store);
  createMutationsSharer({
    predicate: [
      ...vuexSharedMutations,
      'leave/setShowLeaveMessage',
    ],
  })(store);

  if (!isProduction) {
    persistCurrency.plugin(store);
  }
};
