const state = {
  isProgress: null,
  leaveTo: null,
  showLeaveMessage: true,
};

const getters = {
  leaveTo: state => state.leaveTo,
  isProgress: state => state.isProgress,
  showLeaveMessage: state => state.showLeaveMessage,
};

const actions = {
  setLeaveTo({ commit }, to) {
    commit('setLeaveTo', to);
  },
  setIsProgress({ commit }, value) {
    commit('setIsProgress', value);
  },
  setShowLeaveMessage({ commit }, value) {
    commit('setShowLeaveMessage', value);
  },
};

const mutations = {
  setLeaveTo: (state, to) => {
    state.leaveTo = to;
  },
  setIsProgress: (state, value) => {
    state.isProgress = value;
  },
  setShowLeaveMessage: (state, value) => {
    state.showLeaveMessage = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
